import { Livewire, Alpine } from '../../vendor/livewire/livewire/dist/livewire.esm';

import flatpickr from "flatpickr";

import Swiper from 'swiper';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';

import.meta.glob([
    '../img/front/**',
]);

import './common/modal'
import './front/badge-scanner'

window.Alpine = Alpine

Livewire.start()

/** Datepickers
* Doc. : https://flatpickr.js.org/options/
*/

var current_lang = localStorage && localStorage.getItem('lang').substring(0, 2)
flatpickr('.time-picker', {
    enableTime: true,
    noCalendar: true,
    dateFormat: current_lang === 'fr' ? 'H:i' : 'h:i K',
    time_24hr: current_lang === 'fr'
});


/** Swiper carousels
 * Doc. : https://swiperjs.com/swiper-api
 */

new Swiper('.swiper-carousel', {
    modules: [Autoplay],
    loop: true,
    autoplay: {
        delay: 2000,
        disableOnInteraction: true,
        pauseOnMouseEnter: true,
    },
});